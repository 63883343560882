import addGlobalEventListener from "./util/addGlobalEventListener";
const navPage = document.querySelector("#mobile-nav");
export function setupNavButtons() {
	addGlobalEventListener("click", "[data-open-nav-button]", (e) => {
		navPage.style.width = "100%";
	});

	addGlobalEventListener("click", "[data-close-nav-button]", (e) => {
		navPage.style.width = "0%";
	});
}
